import React, { useState } from 'react';
import classNames from 'classnames';
import { UncontrolledTooltip } from 'reactstrap';
import {
	TITLE_ATTACHED_FILES
	, TITLE_COLLABORATE_FILES
	, DEL_ANSWER_ATTACHMENT
	// , DEL_NOTES_ATTACHMENT
	, FILTER_ALL_MESSAGE
	, FILTER_BY_AGENT
	, FILTER_BY_SUMMARIZED_HISTORY
	, FILTER_BY_CUSTOMER
	, FILTER_BY_COLLABORATION
	, FILTER_BY_INTERNAL_MESSAGE
} from '../../common/v5/constants';
import { I } from '../../common/v5/config.js';
import Anchor from '../../reactcomponents/Anchor';
import ProfilePhoto from '../../reactcomponents/ProfilePhoto';
import { AttachmentBox } from './Attachments';
import VisitorPathWindow from './VisitorPath';
import { Errand } from './ErrandBoxHeaderInfo';
import { sanitizeText, getErrandIcons, cleanChannelIdentifier} from '../../common/v5/helpers';
import { ButtonIcon } from '../../reactcomponents/HeaderActions';
import { RPLY_TAB_ATTACHMENT } from '../../common/v5/constants';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { centionTomatoOrange } from '../../styles/_variables.js'

export function withAcquireBox(AcquireErrandsComponent) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleClickOutside = this.handleClickOutside.bind(this);
		}
		handleClickOutside() {
			this.props.onToggleAcquire(false);
		}
		render() {
			const {
					showAcquire
					, hasOtherErrands
					, hasOpenErrand
					, onToggleAcquire
				} = this.props
				, acqBttnClass = classNames(
					'ignore-react-onclickoutside aquired-button'
					, {'dropped': showAcquire, 'with-errands': hasOtherErrands, 'has-acquirable': hasOpenErrand}
				)
				, iconMemberClass = classNames(
					'icon-member-repeat'
					, {'has-acquirable': hasOpenErrand}
				)
				;
				let hideAcquireBtn = true;//tk-todo: hide/remove btn permanently
			return (
				<div className="aquired-errands" title={I('Other contacts')}>
					{!hideAcquireBtn && <Anchor
						className={acqBttnClass}
						data-qa-id={"QA_openAcquiredErrands"}
						onClick={onToggleAcquire}
					>
						<i className={iconMemberClass} />
					</Anchor>}
					<AcquireErrandsComponent
						showAcquire={showAcquire}
						onClickOutside={this.handleClickOutside}
					/>
				</div>
			);
		}
	};
}

const ClientName = ({ name, title }) => (
		<div
			className="errand-box-user-name user-icon-row"
			title={title}
			data-qa-id={"QA_clientName"}
		>
			{name}
		</div>
);

const ClientContactCardLink = ({ text, onClick, disabled, className }) => (
	<Anchor
		disabled={disabled}
		onClick={onClick}
		className={className}
		data-qa-id={"QA_openContactCard"}
	>
		{text}
	</Anchor>
);

class MembershipStatus extends React.PureComponent {
	constructor(props){
		super(props);
	}
	componentDidMount(){
		if(this.props.onCheckingMembershipStatus){
			this.props.onCheckingMembershipStatus(this.props.email);
		}
	}
	render(){
		const {membershipStatus, email} = this.props;
		let iconClass = "icon-member", statusTitle = "";
		if(membershipStatus){
			if(membershipStatus.Profile == "1"){
				iconClass += " active";
			}
			statusTitle = membershipStatus.Message;
		}
		return(
			<span className="membership-status" title={statusTitle}><i className={iconClass}></i></span>
		);
	}
}

export function withUserInfo(AcquireComponent) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleOpenContactCard = this.handleOpenContactCard.bind(this);
		}
		handleOpenContactCard() {
			this.props.onToggleContactCard(true);
		}
		render() {
			const {
					client
					, isOtherContact
					, showAcquire
					, hasOtherErrands
					, hasOpenErrand
					, hasContactCard
					, hasClientNotes
					, hasMembershipStatusFeature
					, onCheckingMembershipStatus
					, membershipStatus
					, onToggleAcquire
					, nameOncontactCard
					, serviceName
					, serviceIcon
					, sourceName
				} = this.props
				;
			let serviceSource = serviceName;
			if(serviceName == "Chat" && sourceName) {
				serviceSource = sourceName;
			}
			let clientName = cleanChannelIdentifier(serviceSource, client.name, true);
			let displayAddress = cleanChannelIdentifier(serviceSource, client.fromAddress);
			if(hasContactCard && nameOncontactCard &&
				nameOncontactCard !== "") {
					clientName = nameOncontactCard;
			}
			const newUI_UserInfoComp = <div className="errand-box-user-info v5">
										{!isOtherContact &&
											<AcquireComponent
												showAcquire={showAcquire}
												hasOtherErrands={hasOtherErrands}
												hasOpenErrand={hasOpenErrand}
												onToggleAcquire={onToggleAcquire}
											/>
										}
										<div className="contact-wrapper">
											<div className="column profile-photo">
												{
													hasClientNotes &&
													<span
														title={I("Have more information")}
														className="client-notes-status"
														data-qa-id="client-notes-status">
													</span>
												}
												<ProfilePhoto
													width={"23"}
													isAgent={false}
													className={"errand-box-user-photo"}
													photo={client.photo}
												/>
											</div>
											<div className=" column user-info">
												<div className='label-row client-info'>
												{!isOtherContact &&
													<ButtonIcon
														className={classNames("icon contact-card user-icon-row", {"inactive" : !hasContactCard})}
														icon={hasContactCard ? "icon-contact-book" : "icon-contact-add"}
														onClick={this.handleOpenContactCard}
														title={I("Open contact card")}
														overflow={-30}
														data-qa-id={"btn-OpenContactCard"}
														noTooltip={true}
													/>
												}
												{hasMembershipStatusFeature &&
													<MembershipStatus
														email={client.fromAddress}
														onCheckingMembershipStatus={onCheckingMembershipStatus}
														membershipStatus={membershipStatus}
													/>
												}
												<ClientName name={clientName} title={client.fromAddress ? client.fromAddress : client.name} />
												</div>
												<div className="label-row errand-box-user-address" data-qa-id="QA_errandInfo_email" title={client.fromAddress}>
													{displayAddress}
												</div>
												<div className="label-row channel-info">
													{serviceIcon}
													<span className="errand-channel" data-qa-id="QA_errandInfo_channel">
														{serviceName}
													</span>
												</div>
											</div>
										</div>
								</div>;
			return (newUI_UserInfoComp);
		}
	};
}

const AttachmentPanelBar = ({ show, onMouseEnter, onMouseLeave, onClick }) => (
	<div className="errand-box-panel-trigger">
		<div
			className="capsule-bar"
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
			id={"togglePanelTrigger"}
		></div>
		<UncontrolledTooltip placement="top" target="togglePanelTrigger" hideArrow={true} className="c3-general-tooltip">
			{show ? I("Hide this panel") : I("Show this panel")}
		</UncontrolledTooltip>
	</div>
);

const AttachmentPanel = ({
	hidden
	, isOtherContact
	, onMouseEnter
	, onMouseLeave
	, totalMessages
	, totalAgents
	, totalInternalComments
	, totalCustomers
	, totalCollaborations
	, totalAttachments
	, onClickAttachment
	, fileTitle
	, onClickAllMessage
	, onClickAgents
	, onClickIC
	, onClickCustomers
	, onClickCollaborations
	, filterPopup
}) => {
	if (isOtherContact) {
		return null;
	}
	return (
		<div
			className="more-block active"
			hidden={hidden}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className="more-title">{I('SHOW')}:</div>
			<Anchor data-qa-id="anchor_count_msg" onClick={onClickAllMessage} className={filterPopup.allMessages ? "active" : ""}>{I('All messages')}({totalMessages})</Anchor>
			<Anchor data-qa-id="anchor_count_agent" onClick={onClickAgents} className={(totalAgents !== 0 ? "" : "empty") + (filterPopup.byAgents ? "active" : "")}>
				{I('Agents')}({totalAgents})
			</Anchor>
			<Anchor data-qa-id="anchor_count_comments" onClick={onClickIC} className={(totalInternalComments !== 0 ? "" : "empty") + (filterPopup.byInternalMessages ? "active" : "") }>
				{I('Internal Comments')}({totalInternalComments})
			</Anchor>
			<Anchor data-qa-id="anchor_count_cust" onClick={onClickCustomers} className={(totalCustomers !== 0 ? "" : "empty") + (filterPopup.byCustomers ? "active" : "")}>
				{I('Customer')}({totalCustomers})
			</Anchor>
			<Anchor data-qa-id="anchor_count_collab" onClick={onClickCollaborations} className={(totalCollaborations !== 0 ? "" : "empty") + (filterPopup.byCollaborations ? "active" : "")}>
				{I('Collaborations')}({totalCollaborations})
			</Anchor>
			<Anchor data-qa-id="anchor_count_files" className="attach" onClick={onClickAttachment}>
				{fileTitle}({totalAttachments})
			</Anchor>
		</div>
	);
};

const ErrandHeaderFilterBar = ({ children, onMouseEnter, onMouseLeave }) => {
	const [isVisible, setIsVisible] = useState(true);

	const toggleVisibility = () => {
		setIsVisible(prevState => !prevState);
	};

	return (
		<div className="errand-box-filter-bar" onMouseLeave={onMouseLeave}>
			<div className="peak-tab" onMouseEnter={onMouseEnter}>
				<i className="icon-preview" onClick={toggleVisibility}></i>
			</div>
			
			{isVisible && children}
		</div>
	);
};

export function withHeaderLeftBox(InfoContainer) {
	return ({
		subject
		, service
		, serviceIcon
		, serviceName
		, chatSource
		, eid
		, displayId
		, insertClassSubject
		, isOtherContact
		, isChat
		, onShowSelection
		, onReturnToInbox
		, onHandleAttachment
		, client
		, hasClientNotes
		, hasContactCard
		, hasOpenErrand
		, hasOtherErrands
		, onCheckingMembershipStatus
		, hasMembershipStatusFeature
		, membershipStatus
		, nameOncontactCard
		, showAcquire
		, onToggleAcquire
		, onToggleContactCard
		, sourceName
	}) => (
		<div className="errand-box-info">
			{/*TODO: permantly remove this after new errandbox UI? */}
			{ (service === Workflow.Errand.SERVICE_EMAIL || service === Workflow.Errand.SERVICE_MANUAL) &&
				<div className="errand-subject" hidden={true}>
					<div className="truncate" title={subject}>{sanitizeText(subject)}</div>
				</div>
			}
			<div className={!insertClassSubject ? "errand-box-info-inner" : "errand-box-info-inner subject"}>
				<div className="errand-box-info-icon" hidden={true}>
					<span className="errand-id" data-qa-id="QA_errandInfo_id">
						{"#"+displayId}
					</span>
					<div>{serviceIcon}</div>
					<span title={L(serviceName)} className="errand-channel" data-qa-id="QA_errandInfo_channel">
						{L(serviceName)}{chatSource}
					</span>
				</div>
				<InfoContainer
					me='ehdr'
					eid={eid}
					isOtherContact={isOtherContact}
					onShowSelection={onShowSelection}
					onReturnToInbox={onReturnToInbox}
					onHandleAttachment={onHandleAttachment}
					client={client}
					onToggleAcquire={onToggleAcquire}
					onToggleContactCard={onToggleContactCard}
					showAcquire={showAcquire}
					hasOpenErrand={hasOpenErrand}
					hasOtherErrands={hasOtherErrands}
					hasMembershipStatusFeature={hasMembershipStatusFeature}
					onCheckingMembershipStatus={onCheckingMembershipStatus}
					membershipStatus={membershipStatus}
					hasContactCard={hasContactCard}
					hasClientNotes={hasClientNotes}
					nameOncontactCard={nameOncontactCard}
					serviceIcon={serviceIcon}
					serviceName={serviceName}
					sourceName={sourceName}
				/>
			</div>
		</div>
	);
}
withHeaderLeftBox.displayName = "withHeaderLeftBox";

const LinearBarStyle = {
	backgroundColor: '#E8E0FF',
	'& .MuiLinearProgress-bar': {
		backgroundColor: '#8B63FF'
	},
	borderRadius: '4px',
	height: '6px',
};

const CustomizedProgressBars = ({title, value}) => {
	const capTitle = title.charAt(0).toUpperCase() + title.slice(1);
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ minWidth: 35, width: 70 }}>
				<Tooltip title={capTitle} placement="left-end">
					<Typography variant="body2" color={centionTomatoOrange} fontSize={12} fontWeight={400} lineHeight={'20px'} noWrap={true}>{capTitle}:</Typography>
				</Tooltip>
			</Box>
			<Box sx={{ width: '100%', mr: 1, maxWidth: 60 }}>
				<Tooltip title={value+"%"} placement="right-end" arrow>
					<LinearProgress sx={LinearBarStyle} variant="determinate" value={value} />
				</Tooltip>
			</Box>
		</Box>
	);
}

function sortAndFilter(obj) {
	const sortedArray = Object.entries(obj).sort((a, b) => b[1] - a[1]);
	const resultObject = Object.fromEntries(sortedArray.slice(0, 3));
	return resultObject;
}


const ERRAND_MAX_AUTO_CLASSIFY_LABELS_DISPLAY = 3;

const ErrandAutoClassificationLabels = ({data}) => {
	const validJSONClassify = JSON.parse(data);
	const newValidJSONClassify = {};
	for(var prop in validJSONClassify){
		if (Object.prototype.hasOwnProperty.call(validJSONClassify,
			prop)) {
				if(prop !== "language" && prop !== "language-score" && prop !== "sentiment" && prop !== "sentiment-score"){
					newValidJSONClassify[prop] = validJSONClassify[prop];
				}
		}
	}
	const autoTagsList = sortAndFilter(newValidJSONClassify);
	let autoClassifyRow = [];
	if (autoTagsList != null) {
		let loop = 0;
		for(var prop in autoTagsList){
			if (Object.prototype.hasOwnProperty.call(autoTagsList,
				prop)) {
					const percentage = autoTagsList[prop] * 100;
					let percentageNumber = Number(percentage);
					percentageNumber = Math.round(percentage * 100) / 100;
					autoClassifyRow.push(<CustomizedProgressBars key={"AutoClassifyLabel_"+prop} value={percentageNumber} title={prop} />);
				loop++;
			}
			if(loop >= ERRAND_MAX_AUTO_CLASSIFY_LABELS_DISPLAY){
				break;
			}
		}
	}
	return <div className ="auto-classify-box">
		{autoClassifyRow}
	</div>
}

function hasBasicAutoClassifyLabelsOnly(obj) {
	const requiredKeys = ["language", "language-score", "sentiment", "sentiment-score"];
	const objectKeys = Object.keys(obj);
	return objectKeys.length === 4 && requiredKeys.every(key => objectKeys.includes(key));
}

//
export function withErrandBoxHeader(LeftBoxComponent, UserInfoComponent) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);

			this.state = {
				showAcquireHeader: true
			};
			this.handleOnDeleteAttachment = this.handleOnDeleteAttachment.bind(this);
			this.handleSelectAttachment = this.handleSelectAttachment.bind(this);
			this.handleToggleAcquire = this.handleToggleAcquire.bind(this);
			this.handleShowSelection = this.handleShowSelection.bind(this);
			this.handleActiveAttachmentPanel = this.handleActiveAttachmentPanel.bind(this);
			this.handleInactiveAttachmentPanel = this.handleInactiveAttachmentPanel.bind(this);
			this.handlePersistActiveAttachmentPanel = this.handlePersistActiveAttachmentPanel.bind(this);
			this.handleToggleContactCard = this.handleToggleContactCard.bind(this);
			this.handleToggleVisitorPath = this.handleToggleVisitorPath.bind(this);
			this.handleCloseVisitorPath = this.handleCloseVisitorPath.bind(this);
			this.handleClickAllMessage		= this.handleClickAllMessage.bind(this);
			this.handleClickSummarizedMessage = this.handleClickSummarizedMessage.bind(this);
			this.handleClickAgents			= this.handleClickAgents.bind(this);
			this.handleClickIC			= this.handleClickIC.bind(this);
			this.handleClickCustomers 		= this.handleClickCustomers.bind(this);
			this.handleClickCollaborations	= this.handleClickCollaborations.bind(this);
			this.handleToggleErrandInfoBox = this.handleToggleErrandInfoBox.bind(this);
			this.handleToggleAcquireErrandInfoBox = this.handleToggleAcquireErrandInfoBox.bind(this);
		}

		handleToggleErrandInfoBox(){
			if(this.props.onClickHeaderPanel){
				this.props.onClickHeaderPanel(!this.props.showHeader)
			}
		}

		handleToggleAcquireErrandInfoBox(){
			let showAcquireHeader = this.state.showAcquireHeader;
			this.setState({
				showAcquireHeader: !showAcquireHeader
			});
		}

		handleSelectAttachment(id, value, history) {
			this.props.onSelectAttachment(
				id
				, value
				, this.props.currentReply
				, history
			);
		}
		handleToggleAcquire(v) {
			if (typeof v === 'boolean') {
				this.props.onToggleAcquire(v);
				return;
			}
			this.props.onToggleAcquire(!this.props.showAcquire);
		}
		handleShowSelection() {
			this.props.onToggleAttachmentPanel(false, true);
		}
		handleAttachment = (e) => {
			const { totalAttachmentsSize, showAttachment } = this.props;
			if (totalAttachmentsSize <= 0) return;
			if(cflag.IsActive("2023-08-31.CEN-1440.new.errand.attachment.design")){
				const { selectedReplyTab, showReplyBox, onToggleReply, onToggleAttachmentList, onSelectTab } = this.props;
				if(!showReplyBox) {
					onToggleReply();
					onToggleAttachmentList(false);
					onSelectTab(RPLY_TAB_ATTACHMENT);
				} else if(selectedReplyTab !== RPLY_TAB_ATTACHMENT) {
					onToggleAttachmentList(false);
					onSelectTab(RPLY_TAB_ATTACHMENT)
				} else {
					onToggleAttachmentList(showAttachment);
				}
			} else {
				//old attachment panel. Can remove once cflag confirmed
			this.props.onToggleAttachmentList(showAttachment);
			this.props.onToggleAttachmentPanel(false, true);
			}
		}
		handleOnDeleteAttachment(aId, type, currentReply, fileName) {
			this.props.onDeleteAnswerAttachment(
				DEL_ANSWER_ATTACHMENT
				, aId
				, type
				, currentReply
				, fileName
			);
		}
		handleActiveAttachmentPanel() {
			this.props.onToggleAttachmentPanel(true);
		}
		handleInactiveAttachmentPanel() {
			this.props.onToggleAttachmentPanel(false);
		}
		handleShowHeaderFilter = () => {
			//wantShow and permanentShow
			this.props.onToggleAttachmentPanel(true, true);
		}
		handleInstantHideHeaderFilter = () => {
			//notWantShow and InstantHide
			this.props.onToggleAttachmentPanel(false, true);
		}
		handlePersistActiveAttachmentPanel() {
			this.props.onToggleAttachmentPanel(true, true);
		}
		handleToggleContactCard() {
			this.props.onToggleContactCard(true);
		}
		handleToggleVisitorPath() {
			this.props.onToggleVisitorPath(true);
		}
		handleCloseVisitorPath(){
			this.props.onToggleVisitorPath(false);
		}
		handleClickAllMessage(){
			this.handleTogglePopup(FILTER_ALL_MESSAGE, true);
		}
		handleClickSummarizedMessage() {
			  // Check if the summarized history popup is already open
			  if (this.props.filterPopup.bySumHistory) {
				return; 
			}
			let areaId = this.props.errand.area;
			let errandId = this.props.errand.id;
			let threadId = this.props.threadId;
			let history = this.props.history;
			let aggregatedStrings = [];  
			if (history && Array.isArray(history.data)) {
				history.data.forEach(entry => {
					let messageContent = entry.message || 'N/A'; 
					let agentAnswer = entry.answer || 'N/A';  
	
					if (messageContent !== 'N/A') {
						aggregatedStrings.push(messageContent);
					}
					if (agentAnswer !== 'N/A') {
						aggregatedStrings.push(agentAnswer);
					}
				});
			} else {
				aggregatedStrings = ['No history data available.'];
			}
			this.props.onSummarizedHistory({ aggregatedStrings, areaId, errandId ,threadId })
			this.handleTogglePopup(FILTER_BY_SUMMARIZED_HISTORY, true);
		}	
		handleClickAgents(){
			this.handleTogglePopup(FILTER_BY_AGENT, (!this.props.filterPopup.byAgents ? true : this.props.filterPopup.byAgents));
		}
		handleClickIC(){
			this.handleTogglePopup(FILTER_BY_INTERNAL_MESSAGE, (!this.props.filterPopup.byInternalMessages ? true : this.props.filterPopup.byInternalMessages));
		}
		handleClickCustomers(){
			this.handleTogglePopup(FILTER_BY_CUSTOMER, (!this.props.filterPopup.byCustomers ? true : this.props.filterPopup.byCustomers));
		}
		handleClickCollaborations(){
			this.handleTogglePopup(FILTER_BY_COLLABORATION, (!this.props.filterPopup.byCollaborations ? true : this.props.filterPopup.byCollaborations));
		}
		handleTogglePopup(filterElem, filterVal){
			this.props.onTogglePopupHistoryElem(filterElem, filterVal);
		}
		handleClickAddContactCard = () => {
			this.props.onAddSuggestContact(this.props.suggestAddTo, this.props.errand.service, this.props.client.fromAddress);
		}
		render() {
			if (!this.props.errand) {
				return null;
			}
			const {
				attachmentList,
				attachmentSize,
				client,
				chat,
				collaborationList,
				currentReply,
				eid,
				errand,
				extendedData,
				hasOpenErrand,
				hasOtherErrands,
				history,
				isCollaboration,
				isOtherContact,
				mobile,
				canInsertIncomingAttachments,
				questionAttachments,
				selectedList,
				serverServices,
				showAcquire,
				showAttachment,
				showVisitorPath,
				collaborateAnsAttachments,
				savedAttachments,
				totalAttachmentsSize,
				uploadedAttachments,
				archiveAttachments,
				libraryAttachments,
				toggleAttachBox,
				textFilePreview,
				csvFilePreview,
				onGetAttachmentContent,
				deleteAllAgentAttachment,
				internalComments,
				agents,
				customers,
				onReturnToInbox,
				Workflow,
				hasMembershipStatusFeature,
				onCheckingMembershipStatus,
				membershipStatus,
				filterPopup,
				showHeader,
				hasContactCard,
				hasClientNotes,
				suggestAddTo,
				includeHistory,
				extForwardHistory,
				onCheckAllAttachment,
				allAttachmentChecked,
				historyAttachmentList,
				selectedHistoryAttachmentList,
				nameOncontactCard,
			} = this.props;

			const {
				showAcquireHeader
			} = this.state;
			let hide, myClass = 'errand-box-header', fileTitle;
			if (mobile && mobile.viewErrand) {
				myClass += ' mobile';
				if (!isOtherContact && !mobile.viewErrandHeader) {
					hide = true;
				}
			}
			if (!isOtherContact && !hide && !showHeader) {
				hide = true;
			}

			if (isOtherContact && !showAcquireHeader && !hide) {
				hide = true;
			}
			if(!isCollaboration) {
				fileTitle = TITLE_ATTACHED_FILES;
			} else {
				fileTitle = TITLE_COLLABORATE_FILES;
			}
			let totalHistory = 0, collaborations = 0;
			if (collaborationList !== null)
				collaborations = collaborationList.length;
			if (history != null)
				totalHistory = history.data.length;
			let visitorIcon = process.env.CLOUDFRONT_URL + "/img/visitor.jpg";
			let subject = errand.subject, service = errand.service;
			const serviceIcon = getErrandIcons(errand.service, chat, (errand.fbPms || errand.twitterPm), errand.sourceId);
			let serviceName = errand.serviceName, displayId = errand.displayId;
			let insertClassSubject = false;
			if(typeof Workflow !== "undefined" && Workflow &&
				(service === Workflow.Errand.SERVICE_EMAIL ||
				service === Workflow.Errand.SERVICE_MANUAL)){
				insertClassSubject = true;
			}
			let chatSource = "";
			if (chat) {
				if (errand.sourceId === Workflow.Errand.SERVICE_FACEBOOK
					|| errand.sourceId === Workflow.Errand.SERVICE_TWITTER
					|| errand.sourceId === Workflow.Errand.SERVICE_LINE
					|| errand.sourceId === Workflow.Errand.SERVICE_WHATSAPP
					|| errand.sourceId === Workflow.Errand.SERVICE_INSTAGRAM
					){
					chatSource = <b> ( {L(errand.sourceName)} )</b>;
				}
			}
			myClass += " v5";
			let suggestPanel = null;
			if (!isOtherContact && suggestAddTo) {
				let suggestAddToContactCard = I("New contact information was found on '{NameOncontactCard}'. Would you like to add this to the contactcard?")
				.replace('{NameOncontactCard}', nameOncontactCard);

				suggestPanel = <div className="errand-add-contactcard" hidden={hide}>
					{suggestAddToContactCard}
					<a href="#" style={{marginLeft: "5px"}} onClick={this.handleClickAddContactCard} className="add-contact">
						{I("Add")}
					</a>
				</div>
			}
			LeftBoxComponent.displayName = "LeftBoxComponent"; // to show the name on dev tools
			let showAutoClassificationLabels = false;
			if(errand.autoClassification){
				const validJSONClassify = JSON.parse(errand.autoClassification);
				if(hasBasicAutoClassifyLabelsOnly(validJSONClassify)){
					showAutoClassificationLabels = false;
				} else {
					showAutoClassificationLabels = true;
				}
			}
			return (
				<div id="errand-info-holder" hidden={mobile ? mobile.viewErrandHeader : false}>
					<div className={myClass} hidden={hide}>
						<LeftBoxComponent
							subject={subject}
							insertClassSubject={insertClassSubject}
							service={service}
							serviceIcon={serviceIcon}
							serviceName={serviceName}
							chatSource={chatSource}
							displayId={displayId}
							client={client}
							isOtherContact={isOtherContact}
							onToggleAcquire={this.handleToggleAcquire}
							onToggleContactCard={this.handleToggleContactCard}
							showAcquire={showAcquire}
							hasOpenErrand={hasOpenErrand}
							hasOtherErrands={hasOtherErrands}
							hasMembershipStatusFeature={hasMembershipStatusFeature}
							onCheckingMembershipStatus={onCheckingMembershipStatus}
							membershipStatus={membershipStatus}
							hasContactCard={hasContactCard}
							hasClientNotes={hasClientNotes}
							nameOncontactCard={nameOncontactCard}
							sourceName={errand.sourceName}
							eid={this.props.eid}
						/>
						{
							showAutoClassificationLabels ? <ErrandAutoClassificationLabels data={errand.autoClassification} /> : null
						}
						<UserInfoComponent
							isChat={chat ? true : false}
							isOtherContact={isOtherContact}
							insertClassSubject={insertClassSubject}
							subject={subject}
							service={service}
							serviceIcon={serviceIcon}
							serviceName={serviceName}
							chatSource={chatSource}
							eid={eid}
							displayId={displayId}
							onShowSelection={this.handleShowSelection}
							onReturnToInbox={onReturnToInbox}
							onHandleAttachment={this.handleAttachment}
						/>
					</div>
					{suggestPanel}
					{!isOtherContact
						? <AttachmentPanelBar
							show={showHeader}
							onClick={this.handleToggleErrandInfoBox}
						/>
						: <AttachmentPanelBar
							show={showAcquireHeader}
							onClick={this.handleToggleAcquireErrandInfoBox}
						/>
					}
					{!chat && filterPopup &&
						<ErrandHeaderFilterBar onMouseEnter={this.handleShowHeaderFilter} onMouseLeave={this.handleInstantHideHeaderFilter}>
							<div className="box-container more-block active"
								// hidden={!this.props.showAttachmentPanel}
								// onMouseEnter={this.handlePersistActiveAttachmentPanel}
								// onMouseLeave={this.handleInstantHideHeaderFilter}
							>
								<Anchor data-qa-id="anchor_count_msg" onClick={this.handleClickAllMessage} className={filterPopup.allMessages ? "active" : ""}>
									{I('All messages')}({totalHistory})
								</Anchor>
								<Anchor data-qa-id="anchor_count_sum" onClick={this.handleClickSummarizedMessage} className={filterPopup.bySumHistory ? "active" : ""}>
									{I('Summarized History')}({1})
								</Anchor>
								<Anchor data-qa-id="anchor_count_agent" onClick={this.handleClickAgents} className={(agents !== 0 ? "" : "empty") + (filterPopup.byAgents ? "active" : "")}>
									{I('Agents')}({agents})
								</Anchor>
								<Anchor data-qa-id="anchor_count_cust" onClick={this.handleClickCustomers} className={(customers !== 0 ? "" : "empty") + (filterPopup.byCustomers ? "active" : "")}>
									{I('Customer')}({customers})
								</Anchor>
								<Anchor data-qa-id="anchor_count_files" className="attach" onClick={this.handleAttachment}>
									{fileTitle}({attachmentSize})
								</Anchor>
							</div>
						</ErrandHeaderFilterBar>
					}
					{!cflag.IsActive("2023-08-31.CEN-1440.new.errand.attachment.design") &&
					!isOtherContact &&
						<AttachmentBox
							show={showAttachment && totalAttachmentsSize > 0}
							errand={errand} currentReply={currentReply}
							list={attachmentList} size={attachmentSize}
							canInsertIncomingAttachments={canInsertIncomingAttachments}
							selectedList={selectedList}
							isCollaboration={isCollaboration}
							collaborateAnsAttachments={collaborateAnsAttachments}
							savedAttachments={savedAttachments}
							questionAttachments={questionAttachments}
							uploadedAttachments={uploadedAttachments}
							archiveFiles={archiveAttachments}
							libraryFiles={libraryAttachments}
							onSelect={this.handleSelectAttachment}
							onDeleteAttch={this.handleOnDeleteAttachment}
							onGetAttachmentContent={onGetAttachmentContent}
							textFilePreview={textFilePreview}
							csvFilePreview={csvFilePreview}
							deleteAllAgentAttachment={deleteAllAgentAttachment}
							includeHistory={includeHistory}
							extForwardHistory={extForwardHistory}
							onCheckAllAttachment={onCheckAllAttachment}
							allAttachmentChecked={allAttachmentChecked}
							historyAttachmentList={historyAttachmentList}
							selectedHistoryAttachmentList={selectedHistoryAttachmentList}
						/>
					}
				</div>
			);
		}
	}
}

withErrandBoxHeader.displayName = "withErrandBoxHeader";